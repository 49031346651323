import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueSimpleMarkdown from "vue-simple-markdown";

Vue.use(VueSimpleMarkdown);
Vue.config.productionTip = false;

Vue.mixin({
  methods: {
    personen (aantal) {
      if (aantal === 1) {
        return "persoon";
      }
      return "personen";
    }
  },
  computed: {
    bgColor () {
      if (document.domain.includes("in-credible")) {
        return "background-color: #ed1c24; color: white;";
      } else {
        return "background-color: #ed1c24; color: white;";
      }
    },
    primaryColor () {
      if (document.domain.includes("in-credible")) {
        return "color: #ed1c24;";
      } else {
        return "color: #ed1c24;";
      }
    },
    logo () {
      if (document.domain.includes("in-credible")) {
        return require("@/assets/img/logo-credible.png");
      } else {
        return require("@/assets/img/logo-credible.png");
      }
    },
    total () {
      let total = 0;
      this.$store.state.cart.forEach(element => {
        total += element.quantity * element.product.prijs;
      });
      return total;
    }
  }
});

store.subscribe((mutation, state) => {
  if (mutation.type === "SET_TYPE") {
    state.cart = [];
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
