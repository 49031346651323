<template>
  <div class="enkel-gerecht">
    <div class="flex">
      <div>
        <h3>{{ item.naam }}</h3>
        <p class="desc">{{ item.omschrijving }}</p>
        <div class="price" :style="primaryColor">
          {{ formatPrice(item.prijs) }}
        </div>
      </div>
      <div class="img-wrapper">
        <img :src="item.afbeelding" />
      </div>
    </div>
    <AddToCart :product="item" />
  </div>
</template>

<script>
import AddToCart from "./AddToCart";
export default {
  name: "EnkelGerecht",
  components: {
    AddToCart
  },
  props: {
    item: Object
  },
  methods: {
    formatPrice(price) {
      return "€" + price.toFixed(2).replace(".", ",");
    }
  }
};
</script>

<style lang="scss" scoped>
.enkel-gerecht {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  padding: 2rem;
  margin-bottom: 2rem;
  border-radius: 10px;
}

h3 {
  margin-top: 0;
}

p,
.desc {
  color: grey;
  white-space: pre-wrap;
}

.price {
  font-weight: bold;
  margin-top: 1rem;
}

.img-wrapper {
  padding-left: 2rem;
  margin-left: auto;
  img {
    width: 150px;
  }
}

@media (max-width: 800px) {
  .enkel-gerecht {
    padding: 1rem;
  }

  .img-wrapper {
    img {
      width: 100px;
    }
  }
}
</style>
