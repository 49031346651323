import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    cart: [],
    gekozenType: []
  },
  mutations: {
    ADD_TO_CART(state, product) {
      let index = state.cart.findIndex(el => el.product === product);
      if (index == -1) {
        state.cart.push({
          product: product,
          quantity: 1
        });
      } else {
        ++state.cart[index].quantity;
      }
    },
    MINUS_QUANTITY(state, product) {
      let index = state.cart.findIndex(el => el.product === product);
      if (index !== -1) {
        if (state.cart[index].quantity > 1) {
          --state.cart[index].quantity;
        }
      }
    },
    SET_QUANTITY(state, data) {
      let index = state.cart.findIndex(el => el.product === data.product);
      if (index !== -1) {
        state.cart[index].quantity = data.quantity;
      }
    },
    REMOVE_FROM_CART(state, product) {
      let index = state.cart.findIndex(el => el.product === product);
      if (index !== -1) {
        state.cart.splice(index, 1);
      }
    },
    SET_TYPE(state, type) {
      state.gekozenType = type;
    }
  },
  actions: {},
  modules: {}
});
