<template>
  <div>
    <div class="button-wrap">
      <button
        v-for="(item, i) in enabledTypes"
        :key="i"
        @click="gekozenType = item"
        :class="{ active: item === gekozenType }"
        class="button--type"
      >
        {{ capitalize(item.title) }}
      </button>
    </div>
    <p
      class="info-box par"
      style="margin-top: 1rem; background-color: #f3f3f3; padding: 0.4rem;"
      v-if="gekozenType.bestel_melding"
    >
      <vue-simple-markdown
        :source="gekozenType.bestel_melding"
      ></vue-simple-markdown>
    </p>
    <div v-for="(gang, i) in filteredTypes" :key="i">
      <h2>{{ capitalize(gang.type) }}</h2>
      <EnkelGerecht
        v-for="(item, i) in gang.data.filter(el => !el.uitschakelen)"
        :key="'menu-' + i"
        :item="item"
      />
    </div>
  </div>
</template>

<script>
import EnkelGerecht from "./EnkelGerecht";
import axios from "axios";
export default {
  components: {
    EnkelGerecht
  },
  data() {
    return {
      type: []
    };
  },
  async created() {
    const typesData = require
      .context("../../public/data/", true, /^.*\.json$/)
      .keys();

    this.type = await Promise.all(
      typesData.map(async el => {
        const { data } = await axios.get("/data/" + el.replace("./", ""));
        return {
          ...data,
          typeFileName: el
        };
      })
    );

    this.$store.commit(
      "SET_TYPE",
      this.enabledTypes[this.enabledTypes.length - 1]
    );
  },
  methods: {
    capitalize(s) {
      if (typeof s !== "string") return "";
      return s.charAt(0).toUpperCase() + s.slice(1);
    }
  },
  computed: {
    enabledTypes() {
      return this.type
        .filter(el => el.aan)
        .sort((a, b) => a.volgorde - b.volgorde);
    },
    gekozenType: {
      get: function() {
        return this.$store.state.gekozenType;
      },
      set: function(newValue) {
        this.$store.commit("SET_TYPE", newValue);
      }
    },
    filteredTypes() {
      if (this.gekozenType && this.gekozenType.gerecht) {
        let types = [...new Set(this.gekozenType.gerecht.map(el => el.type))];

        let filteredArray = [];
        types.forEach(element => {
          filteredArray.push({
            type: element,
            data: this.gekozenType.gerecht.filter(el => el.type === element)
          });
        });

        return filteredArray;
      }
      return "";
    }
  }
};
</script>

<style lang="scss" scoped>
h2 {
  font-weight: normal;
  padding: 1rem;
  font-size: 1rem;
  background: rgb(243, 243, 243);
}
.button-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.button--type {
  padding: 0.5rem;
  border: 2px solid #ed1c24;
  background: white;
  margin: 0.3rem;
  color: #ed1c24;
  font-size: 100%;
  &:focus {
    outline: 0;
  }
  &.active {
    color: white;
    background: #ed1c24;
  }
}
</style>
