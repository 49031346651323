<template>
  <div class="add-to-cart" :class="{ expand: expand }">
    <div class="min-del">
      <button @click="minusQuantity()" v-show="quantity > 1">
        -
      </button>
      <button @click="remove()" v-show="quantity === 1">
        <img src="@/assets/img/delete.svg" alt="delete" />
      </button>
    </div>

    <div class="quantity-wrapper">
      <div class="quant-label">
        <span>aantal</span>
      </div>
      <input class="quantity" v-model="quantity" type="number" />
    </div>

    <button class="add" @click="add()" :style="bgColor">
      <img src="@/assets/img/add.svg" alt="plus" />
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  methods: {
    add() {
      this.$store.commit("ADD_TO_CART", this.product);
    },
    remove() {
      this.$store.commit("REMOVE_FROM_CART", this.product);
    },
    minusQuantity() {
      this.$store.commit("MINUS_QUANTITY", this.product);
    }
  },
  computed: {
    quantity: {
      get() {
        if (this.$store.state.cart.length > 0) {
          let prod = this.$store.state.cart.find(
            el => el.product == this.product
          );
          if (prod) {
            return prod.quantity;
          }
        }
        return 0;
      },
      set(value) {
        this.$store.commit("SET_QUANTITY", {
          product: this.product,
          quantity: value
        });
      }
    },
    expand() {
      if (this.quantity > 0) {
        return true;
      }
      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
.quantity {
  border: 1px solid lightgrey;
  height: calc(2rem - 4px);
  border-radius: 10px;
  padding-left: 0.4rem;
  width: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.quant-label {
  color: rgb(161, 161, 161);
  padding-right: 0.5rem;
}

.quantity-wrapper {
  position: absolute;
  right: calc(50% - 2rem);
  height: calc(2rem - 4px);
  top: 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.min-del {
  left: 0;

  button {
    background: grey;
    color: white;
  }
}

.min-del,
.quantity-wrapper {
  transform: translateX(150%);
  transition: 0.6s all ease-in-out;
  visibility: hidden;
  opacity: 0;
  position: absolute;
}

.add-to-cart {
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 2.5rem;
  margin-top: 2.5rem;
  &.expand {
    .quantity-wrapper,
    .min-del {
      visibility: visible;
      opacity: 1;
      transform: translateX(0%);
    }
  }
}
.add {
  position: absolute;
  right: 0;
  top: 0;
}

button {
  cursor: pointer;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 50%;
  box-shadow: 0;
  outline: 0;
  border: 0;
  img {
    width: 60%;
  }
}
</style>
