<template>
  <div id="app">
    <div class="container">
      <div class="logo-wrapper">
        <img :src="require('@/assets/img/7heuvelen-logo.png')" />
      </div>
      <router-view />
      <div class="bottom-nav" :class="{ active: botActive }">
        <div class="container">
          <button :style="bgColor" class="btn" @click="showCart = true">
            Bestelling bekijken <small>(€{{ total.toFixed(2) }})</small>
          </button>
        </div>
      </div>
    </div>
    <Winkelwagen v-if="showCart" v-on:close="showCart = false" />
  </div>
</template>

<script>
import Winkelwagen from "@/components/Winkelwagen";
export default {
  components: {
    Winkelwagen
  },
  data() {
    return {
      showCart: false
    };
  },
  computed: {
    botActive() {
      if (this.total > 0) {
        return true;
      }
      return false;
    }
  }
};
</script>

<style lang="scss">
button {
  cursor: pointer;
}

input,
textarea,
select {
  font-size: 16px;
}
.btn {
  width: 100%;
  border: 0;
  padding: 1rem;
  font-size: 100%;
  font-family: inherit;
  border-radius: 3px;
}
.bottom-nav {
  transform: translateY(100%);
  transition: 0.6s all;
  padding: 1rem 0rem;
  position: fixed;
  background: white;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid lightgrey;
  &.active {
    transform: translateY(0%);
  }
}
@import url("https://fonts.googleapis.com/css2?family=Commissioner:wght@300;500&display=swap");

body {
  font-family: "Commissioner", sans-serif;
  font-weight: 300;
  padding-bottom: 6rem;
}

* {
  box-sizing: border-box;
}

.flex {
  display: flex;
}

.ai_c {
  align-items: center;
}

.container {
  max-width: 800px;
  margin: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

p {
  line-height: 1.5;
}

p.par {
  text-align: center;
  color: grey;
  max-width: 600px;

  margin: auto;
  margin-bottom: 2rem;
}

.logo-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  width: 100%;
  justify-content: center;
  padding: 1rem 0rem;
  img {
    height: 3rem;
    align-self: center;
    width: auto;
    margin: 0.5rem;
  }
}
</style>
