<template>
  <div class="home">
    <p class="par">
      De 7-heuvelenfeesten is een 2-daags evenement op zaterdag 20 & zondag 21
      november 2021 bij de Oase van Oost / In de Kazerne. Koop hier je ticket
      voor de Tribune + Festival (€5).
    </p>
    <AlleGerechten />
  </div>
</template>

<script>
import AlleGerechten from "@/components/AlleGerechten.vue";

export default {
  name: "Home",
  components: {
    AlleGerechten
  }
};
</script>
